<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage level</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Levels
                </div>
              </div>
              <div
                class="breadcrumb-right"
                v-if="checkIsAccessible('level', 'create')"
              >
                <div class="card-toolbar">
                  <v-btn
                    @click="createLevel()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                     level
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getLevel"
                    outlined
                    v-model="search.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.status"
                    v-on:keyup.enter="getLevel"
                    outlined
                    dense
                    :items="status"
                    label="Status"
                    item-text="title"
                    item-value="value"
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click.prevent="getLevel"
                    class="btn btn-primary w-35"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(level, index) of levels"
                    :key="index"
                    v-show="levels.length > 0"
                  >
                    <td class="px-3 wrap-column">
                      <a href="#" @click="editLevel(level)">{{
                        level.title
                      }}</a
                      >
                      <i
                        class="fas fa-circle"
                        :class="level.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>
                    <td class="px-3">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('level', 'edit')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="editLevel(level)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('level', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteLevel(level.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="levels.length == 0">
                    <td class="px-3 text-center" colspan="2">No data available to display.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-or-update ref="create-update" @refresh="getLevel"></create-or-update>
  </v-app>
</template>
<script>
import CreateOrUpdate from "./CreateOrUpdate";
import LevelService from "@/core/services/level/LevelService";

const levelService = new LevelService();

export default {
  name: "level",
  components: {
    CreateOrUpdate,
  },
  data() {
    return {
      loading: false,
      levels: [],
      status: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in-active" },
      ],
      search: {
        status: "active",
      },
    };
  },
  mounted() {
    this.getLevel();
  },
  methods: {
    createLevel() {
      this.$refs["create-update"].createLevel();
    },
    getLevel() {
      this.loading = true;
      levelService
        .paginate(this.search)
        .then((response) => {
          this.levels = response.data;
          this.loading = false;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    editLevel(item) {
      this.$refs["create-update"].editLevel(item);
    },

    deleteLevel(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            levelService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");

                this.getLevel();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
  },
};
</script>
