<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          {{`${edit ? 'Update':"Add"}  level`}}
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="cancelForm">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div class="row">
          <div class="col-12">
            <v-text-field outlined dense v-model="level.title" label="Title"></v-text-field>
            <span class="text-danger" v-if="$v.level.title.$error">This information is required</span>
          </div>

          <div class="col-12">
            <v-select v-model="level.level" :items="levels" outlined dense label="Level"></v-select>
            <span
                class="text-danger"
                v-if="$v.level.level.$error"
            >This information is required</span>
          </div>

          <div class="col-12">
            <v-text-field v-model="level.course_length" type="number" outlined dense label="Course length"></v-text-field>
            <span
                class="text-danger"
                v-if="$v.level.course_length.$error"
            >This information is required</span>
          </div>

          <div class="col-12">
            <v-select v-model="level.length_type" :items="types" item-text="name" item-value="value" outlined dense label="Length type"></v-select>
            <span
                class="text-danger"
                v-if="$v.level.length_type.$error"
            >This information is required</span>
          </div>

          <div class="col-12 col-md-4">
            <v-switch v-model="level.display_in_enrollment" outlined dense label="Display in enrollment"></v-switch>
          </div>

          <div class="col-12 col-md-3">
            <v-switch v-model="level.is_active" outlined dense label="Status"></v-switch>
          </div>

        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            depressed
            @click="cancelForm"
            class="text-gray btn btn-standard"
          >
          Cancel
        </v-btn>
        <v-btn
          depressed
          :loading="loading"
          @click="createOrUpdate()"
          class="text-white ml-2 btn btn-primary"
        >
            {{edit ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import LevelService from "@/core/services/level/LevelService";
import ConfigService from "@/core/services/config/ConfigService";

const levelService = new LevelService();
const configService = new ConfigService();
export default {
  validations: {
    level: {
      title: {required},
      level: {required},
      course_length: {required},
      length_type: {required},
    }
  },
  data(){
    return{
      dialog:false,
      loading:false,
      edit:false,
      level: {
        title: null,
        level: null,
        is_active: true
      },
      levels: [],
      types: [
        {
          name: "Hours",
          value: "hours"
        },
        {
          name: "Months",
          value: "months"
        },
        {
          name: "Years",
          value: "years"
        }
      ],
    }
  },

  methods:{
    cancelForm(){
      this.resetForm();
      this.closeDialog();
    },
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
      this.getLevels();
    },
    createLevel(){
      this.resetForm();
      this.openDialog();
      this.edit=false;
    },
    editLevel(item){
      this.openDialog();
      this.edit=true;
      this.level=item;
    },
    getLevels() {
      configService
          .educationLevels().then(response => {
        this.levels = response.data;
      });
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit){
          this.saveLevel()
        }else{
          this.updateLevel()
        }
      }
    },
    saveLevel(){
      this.loading = true;
      levelService
       .store(this.level)
       .then(response => {
          if (!response.data) {
            this.$snotify.error("Education Level has been already added");
          } else {
            this.loading = false;
            this.$snotify.success("Information added");

          }
          this.resetForm();
          this.closeDialog();
          this.$emit('refresh');
      })
     .catch((error) => {
        this.loading = false;
        this.$snotify.error("Something Went Wrong");
      })
      .finally(()=>{
            this.loading = false;
       });
    },
    updateLevel(){
      this.loading = true;
      levelService
          .update(this.level.id, this.level)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Information updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((error) => {
              this.loading = false;
              this.$snotify.error("Something Went Wrong");
          })
          .finally(()=>{
            this.loading = false;
          });
    },
    resetForm(){
      this.level={
        id: null,
        title: null,
        level: null,
        is_active: true
      };
      this.$v.$reset();
    }
  }
}
</script>
